import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_CATEGORIES_AND_SERVICES,
  GET_CATEGORY_SERVICE,
  GET_COMMON_RESPONSE,
  GET_CATEGORY,
  GET_SERVICES_LIST,
} from "../actions/Category/CategoryActions";
const initialState = {
  getCategoriesAndServies: [],
  serviceGet: [],
  servicesList: [],
  commonResp: "",
  categoryGet: [],
};
export function CategoryReducers(state = initialState, action) {
  if (action.type === GET_CATEGORIES_AND_SERVICES) {
    return {
      ...state,
      getCategoriesAndServies: action.payload,
    };
  }
  if (action.type === GET_CATEGORY_SERVICE) {
    return {
      ...state,
      serviceGet: action.payload,
    };
  }
  if (action.type === GET_SERVICES_LIST) {
    return {
      ...state,
      servicesList: action.payload,
    };
  }
  if (action.type === GET_CATEGORY) {
    return {
      ...state,
      categoryGet: action.payload,
    };
  }
  if (action.type === GET_COMMON_RESPONSE) {
    return {
      ...state,
      commonResp: action.payload,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }
  return state;
}
