import {
  addCategory,
  updateCategory,
  deleteCategory,
  addServices,
  updateServices,
  deleteService,
  getCategoryAndServices,
  getCategoryService,
  editServiceLocation,
  editStaffService,
  getCategory,
  getBusinessServices,
  editAllStaffService,
} from "../../../services/CategoryServices";
import {
  handlingFailedToAuthenticate,
  snackbarAction,
} from "../Authentication/AuthActions";
//
export const GET_CATEGORIES_AND_SERVICES =
  "[get categories and services] get categories and services";

export const GET_CATEGORY_SERVICE =
  "[get category and service] get category and service";
export const GET_SERVICES_LIST = "[get business service] get business service";
export const GET_CATEGORY = "[get category] get category";

export const GET_COMMON_RESPONSE = "[get common response] get common response";

export function AddNewCategory(categoryData, onAddSuccess, navigate) {
  return (dispatch) => {
    addCategory(categoryData)
      .then((response) => {
        const { data, message, status, code } = response?.data;

        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCategoriesAndServices(data?.[0]?.businessId));

          onAddSuccess(data?.[0]?._id, data?.[0]?.name);
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("erroradd-category", error);
      });
  };
}

export function UpdateNewCategory(
  categoryData,
  setEditCategoryDetails,
  navigate
) {
  return (dispatch) => {
    updateCategory(categoryData)
      .then((response) => {
        const { message, data, status, code } = response?.data;
        let sendMessage = {
          code: code,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCategoryData(data?.[0]?._id));
          dispatch(getCategoriesAndServices(data?.[0]?.businessId));

          setEditCategoryDetails(false);
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-category", error);
      });
  };
}

export function categoryDelete(id, businessId, navigate) {
  return (dispatch) => {
    deleteCategory(id)
      .then((response) => {
        const { message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCategoriesAndServices(businessId, navigate));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorcategorydelete-d", error);
      });
  };
}

export function AddNewService(
  serviceData,
  onAddSuccess,
  categoryName,
  navigate
) {
  return (dispatch) => {
    addServices(serviceData)
      .then((response) => {
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCategoriesAndServices(data?.[0]?.businessId));
          onAddSuccess(
            data?.[0]?.categoryId,
            data?.[0]?._id,
            data?.[0]?.name,
            categoryName
          );
        }
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("erroradd-service", error);
      });
  };
}

export function UpdateNewService(serviceData, navigate) {
  return (dispatch) => {
    updateServices(serviceData)
      .then((response) => {
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        if (response?.data?.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
          dispatch(getService(serviceData?.serviceId));
        }
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-service", error);
      });
  };
}

export function serviceDelete(id, businessId, navigate) {
  return (dispatch) => {
    deleteService(id)
      .then((response) => {
        if (response.data.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
        }
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorservicedelete-d", error);
      });
  };
}

export function getCategoryData(id, navigate) {
  return (dispatch) => {
    getCategory(id)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(categorydata(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getCategoriesAndServices(id, navigate) {
  return (dispatch) => {
    getCategoryAndServices(id)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCategoryAndServicesData(data));
        }
        return data;
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getService(id, navigate) {
  return (dispatch) => {
    getCategoryService(id)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getServiceData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getBusinessServicesList(id, navigate) {
  return (dispatch) => {
    getBusinessServices(id)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getBusinessServicesData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function updateStaffService(staffData, navigate) {
  return (dispatch) => {
    editStaffService(staffData)
      .then((response) => {
        const { status, code, message } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
        }

        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-Staff-service", error);
      });
  };
}

export function updateAllStaffService(staffData, navigate) {
  return (dispatch) => {
    editAllStaffService(staffData)
      .then((response) => {
        const { status, code, message } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
        }

        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-Staff-service", error);
      });
  };
}

export function updateServiceLocations(locationData, navigate) {
  return (dispatch) => {
    editServiceLocation(locationData)
      .then((response) => {
        const { status, code, message } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);

          dispatch(snackbarAction(true, sendMessage));
        } else {
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-locations-service", error);
      });
  };
}

export function getCategoryAndServicesData(data) {
  return {
    type: GET_CATEGORIES_AND_SERVICES,
    payload: data,
  };
}

export function getServiceData(data) {
  return {
    type: GET_CATEGORY_SERVICE,
    payload: data,
  };
}

export function categorydata(data) {
  console.log("datssa", data);
  return {
    type: GET_CATEGORY,
    payload: data,
  };
}

export function getBusinessServicesData(data) {
  return {
    type: GET_SERVICES_LIST,
    payload: data,
  };
}
export function getCommonResponse(data) {
  return {
    type: GET_COMMON_RESPONSE,
    payload: data,
  };
}
