import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";
import AuthGuard from "../services/AuthGuard";

// const DashboardDefault = Loadable(
//   lazy(() => import("../pages/dashboard/GetStarted"))
// );
const Calendar = Loadable(lazy(() => import("../pages/calendar/CalendarPage")));
const StaffCheckIn = Loadable(
  lazy(() => import("../pages/staffCheckIn/StaffCheckIn"))
);
const Clients = Loadable(lazy(() => import("../pages/clients/ClientsPage")));

const Queue = Loadable(lazy(() => import("../pages/queuepage/QueuePage")));
const CheckInPage = Loadable(
  lazy(() => import("../pages/checkin/CheckInPage"))
);

const Reports = Loadable(lazy(() => import("../pages/reports/ReportsPage")));

const Products = Loadable(lazy(() => import("../pages/products/ProductsPage")));
const GiftCards = Loadable(
  lazy(() => import("../pages/giftcards/GiftCardsPage"))
);
const Packages = Loadable(lazy(() => import("../pages/packages/PackagesPage")));
const MemberShips = Loadable(
  lazy(() => import("../pages/memberships/MemberShipsPage"))
);
const EmailMarketing = Loadable(
  lazy(() => import("../pages/emailMarketing/EmailMarketing"))
);
const PayRoll = Loadable(lazy(() => import("../pages/payroll/PayrollPage")));
const PayRollId = Loadable(
  lazy(() =>
    import(
      "../pages/payroll/PayRollWeekAndMonthStaffDetail/PayRollWeekAndMonthStaffDetail"
    )
  )
);
const FormSubmissions = Loadable(
  lazy(() => import("../pages/formSubmissions/FormSubmissionPage"))
);

const ClientCheckoutList = Loadable(
  lazy(() =>
    import("../pages/checkoutModule/clientCheckoutList/ClientCheckoutList")
  )
);

const CheckoutDetail = Loadable(
  lazy(() => import("../pages/checkoutModule/checkoutDetail/CheckoutDetail"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard auth={true}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: "",
    //   element: <DashboardDefault />,
    // },
    {
      path: "appointments",
      element: <Calendar />,
    },
    {
      path: "staff-check-in",
      element: <StaffCheckIn />,
    },
    {
      path: "queue",
      element: <Queue />,
    },
    {
      path: "check-in",
      element: <CheckInPage />,
    },
    {
      path: "clients",
      element: <Clients />,
    },
    {
      path: "reports",
      element: <Reports />,
    },
    {
      path: "products",
      element: <Products />,
    },
    {
      path: "gift-cards",
      element: <GiftCards />,
    },
    {
      path: "packages",
      element: <Packages />,
    },
    {
      path: "memberships",
      element: <MemberShips />,
    },
    {
      path: "email-marketing",
      element: <EmailMarketing />,
    },
    {
      path: "payroll",
      element: <PayRoll />,
    },
    {
      path: "payroll/:id",
      element: <PayRollId />,
    },
    {
      path: "form-submissions",
      element: <FormSubmissions />,
    },
    {
      path: "checkout",
      element: <ClientCheckoutList />,
    },
    {
      path: "checkout/:id",
      element: <CheckoutDetail />,
    },
  ],
};

export default MainRoutes;
