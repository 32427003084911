/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from "react-redux";
import ScrollTop from "./components/ScrollTop";
import CustomizedSnackbars from "./components/Snackbar";
import ThemeRoutes from "./routes";
import {
  saveUserDetails,
  // CheckOutAction,
} from "./store/actions/Authentication/AuthActions";
import { visibleBusinessServiceHours } from "./store/actions/Calendar-action/CalendarActions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import moment from "moment";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.auth);
  const { getVisibleHours } = useSelector((state) => state.CalendarReducers);

  // const parseTime = (timeString) => {
  //   if (!timeString) return 0; // Handle cases where timeString is undefined or null

  //   const [time, period] = timeString.split(" ");
  //   let [hours, minutes] = time.split(":").map(Number);

  //   if (period === "PM" && hours !== 12) {
  //     hours += 12;
  //   } else if (period === "AM" && hours === 12) {
  //     hours = 0;
  //   }

  //   return hours * 60 + minutes;
  // };

  useEffect(() => {
    let userToken = JSON.parse(localStorage.getItem("access_token"));
    let userId = JSON.parse(localStorage.getItem("userId"));
    let businessId = JSON.parse(localStorage.getItem("businessId"));

    if (userToken && userId) {
      let clTime = localStorage.getItem("closeTime");

      // Checkout Action check trigger Functionality
      if (getVisibleHours && !clTime) {
        localStorage.setItem("closeTime", getVisibleHours.to);
      }

      if (businessId && !getVisibleHours) {
        dispatch(visibleBusinessServiceHours(businessId._id, navigate));
      }

      if (clTime) {
        // const toMinutes = parseTime(clTime);
        // const now = new Date();
        // const currentMinutes = now.getHours() * 60 + now.getMinutes();
        // if (currentMinutes > toMinutes) {
        //   dispatch(
        //     CheckOutAction({
        //       businessId: businessId._id,
        //       date: moment.utc().local().format(),
        //       to: clTime,
        //     })
        //   );
        // }
      }
      // Checkout Action check trigger Functionality

      dispatch(saveUserDetails(userId, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  return (
    <>
      <ScrollTop>
        {snackbar.open == true && (
          <CustomizedSnackbars
            open={snackbar.open}
            message={snackbar.message}
            type={snackbar.type}
          />
        )}

        <ThemeRoutes />
      </ScrollTop>
    </>
  );
}

export default App;
