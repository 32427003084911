import { Box, Typography } from "@mui/material";
import img from "../../assets/react.svg";
import "./Error.css";
const Somethingwrong = () => {
  return (
    <Box className="errorBox">
      <Typography gutterBottom>
        <img src={img} alt="logo" />
      </Typography>
      <Typography variant="h4" color="secondary">
        Something went wrong
      </Typography>
    </Box>
  );
};

export default Somethingwrong;
