import { lazy } from "react";
import Loadable from "../components/Loadable";
import SettingsLayout from "../layout/SettingsLayout";
import AuthGuard from "../services/AuthGuard";

// Settings Route
const Settings = Loadable(lazy(() => import("../pages/settings/SettingsPage")));
// const BusinessSetup = Loadable(
//   lazy(() =>
//     import("../pages/settings/businessSetup/BusinessDetails/BusinessDetails")
//   )
// );

const StaffMember = Loadable(
  lazy(() => import("../pages/settings/staff/Staff"))
);

const BusinessServices = Loadable(
  lazy(() => import("../pages/settings/businessServices/BusinessServices"))
);
// const CalendarAndAppointments = Loadable(
//   lazy(() =>
//     import("../pages/settings/CalendarAndAppointment/DisplayPreference")
//   )
// );
// ==============================|| MAIN ROUTING ||============================== //

const SettingsRoutes = {
  path: "settings",

  element: (
    <AuthGuard auth={true}>
      <SettingsLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/settings",
      element: <Settings />,
    },
    // {
    //   path: "business-setup/business-details",
    //   element: <BusinessSetup />,
    // },
    {
      path: "staff",
      element: <StaffMember />,
    },
    {
      path: "services",
      element: <BusinessServices />,
    },
    // {
    //   path: "calendar-appointments",
    //   element: <CalendarAndAppointments />,
    // },
  ],
};

export default SettingsRoutes;
