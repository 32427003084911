import { Outlet } from "react-router-dom";

// ==============================|| MINIMAL LAYOUT ||============================== //

const CustomerCheckInLayout = () => (
  <>
    <Outlet />
  </>
);

export default CustomerCheckInLayout;
