import React, { useState } from "react";
import {
  Box,
  useTheme,
  List,
  ListItemText,
  // ListItemIcon,
  Divider,
  ListItemButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function TabsContent({ value, handleChange, handleTabClick, handleClose }) {
  const theme = useTheme();

  const items = [
    {
      label: "Display Perferences",
      value: "1",
      // icon: businessIcon,
      path: "/settings/calendar-appointments/display-preferences",
    },
  ];

  return (
    <List
      sx={{
        "& .Mui-selected": {
          backgroundImage:
            "linear-gradient(to right, rgba(192, 193, 227, 0.1) 90%, #1F234A 97%)",
          color: theme.palette.primary.contrastText,
          fontWeight: 600,
          "& .bs-sidebar-icons": {
            filter: "invert(10) brightness(0.5)",
          },
        },
        paddingTop: "0px !important",
      }}
    >
      {items.map((item) => (
        <React.Fragment key={item.value}>
          {item.value === "5" && (
            <Divider
              sx={{
                marginBlock: "20px",
                borderColor: "#c0c1e3",
                marginLeft: "8px !important",
                marginRight: "8px !important",
              }}
            />
          )}
          <ListItemButton
            selected={value === item.value}
            onClick={(event) => {
              handleChange(event, item.value);
              handleTabClick(item.path);
              handleClose();
            }}
          >
            <ListItemText primary={item.label} />
          </ListItemButton>
        </React.Fragment>
      ))}
    </List>
  );
}

function CalendarAndAppointmentSidebar({ handleClose }) {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#414574",
        textTransform: "capitalize !important",
        color: "#c0c1e3",
        height: "100%",
      }}
    >
      <TabsContent
        value={value}
        handleChange={handleChange}
        handleTabClick={handleTabClick}
        handleClose={handleClose}
      />
    </Box>
  );
}

export default CalendarAndAppointmentSidebar;
