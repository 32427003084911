import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
const isAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem("access_token"));
  return !!user;
};

const AuthGuard = ({ children, auth }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userIsAuthenticated = isAuthenticated();
    setLoading(false);

    if (auth === true && !userIsAuthenticated) {
      navigate("/auth/login");
    } else if (auth === false && userIsAuthenticated) {
      navigate("/appointments");
    }
  }, [auth, navigate]);

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.bool.isRequired,
};

export default AuthGuard;
