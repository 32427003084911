import { Outlet } from "react-router-dom";
import { Box, Grid, useMediaQuery, useTheme, Drawer } from "@mui/material";
import Header from "../MainLayout/Header/Header";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CalendarAndAppointmentSidebar from "./CalendarAndAppointmentSidebar";

// ==============================|| MAIN LAYOUT ||============================== ////

const CalendarAndAppointmentLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box>
      <Header />
      <Box
        sx={{
          paddingTop: "56px",
        }}
      >
        <Grid container columnSpacing={3.75} height="100vh">
          {isSmallScreen ? (
            <>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
                ModalProps={{ keepMounted: true }}
                sx={{
                  "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
                  paddingTop: "56px",
                }}
              >
                <CalendarAndAppointmentSidebar handleClose={toggleDrawer} />
              </Drawer>
            </>
          ) : (
            <Grid item md={2} lg={2}>
              <CalendarAndAppointmentSidebar handleClose={() => {}} />
            </Grid>
          )}

          <Grid
            item
            md={10}
            lg={10}
            sx={{ backgroundColor: "#f2f2f2 !important" }}
          >
            <Box component="main" px={{ xs: 2, sm: 2 }}>
              {isSmallScreen && (
                <Box mt={1}>
                  <MenuIcon onClick={toggleDrawer} />
                </Box>
              )}
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CalendarAndAppointmentLayout;
