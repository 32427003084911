import { authApi } from "./AxiosInstance";

export function addCategory(categoryData) {
  return authApi.post("/api/service/add/category", categoryData);
}

export function updateCategory(categoryData) {
  return authApi.post("/api/service/edit/category", categoryData);
}

export function deleteCategory(id) {
  return authApi.delete(`/api/service/delete/category/${id}`);
}

export function addServices(serviceData) {
  return authApi.post("/api/service/add", serviceData);
}

export function updateServices(serviceData) {
  return authApi.post("/api/service/edit", serviceData);
}

export function deleteService(id) {
  return authApi.delete(`/api/service/delete/${id}`);
}

export function getCategoryAndServices(id) {
  return authApi.get(`/api/service/get_category_list/${id}`);
}
export function getCategory(id) {
  console.log("idsasadafdg", id);
  return authApi.get(`/api/service/catagory/${id}`);
}

export function getCategoryService(id) {
  return authApi.get(`/api/service/getservice/${id}`);
}

export function getBusinessServices(id) {
  return authApi.get(`/api/service/getservicelist/${id}`);
}

export function editServiceLocation(locationData) {
  return authApi.post(`api/service/update/location`, locationData);
}

export function editStaffService(staffData) {
  return authApi.post(`/api/service/update/edit_staff_service`, staffData);
}

export function editAllStaffService(staffData) {
  return authApi.put(`/api/service/update/edit_all_staff_service`, staffData);
}

export function getStaffWeekData(weekStaffData) {
  return authApi.post(`/api/appointment/weekDays`, weekStaffData);
}
