import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_ALL_STAFF_MEMBER,
  GET_STAFF_MEMBER,
  GET_STAFF_MEMBER_WORKING_HOURS,
} from "../actions/Staff-members/StaffMemberActions";
const initialState = {
  getStaff_Member: {},
  getAll_StaffMember: [],
  staffMemberHours: [],
};

export function StaffMemberReducers(state = initialState, action) {
  if (action.type === GET_STAFF_MEMBER) {
    return {
      ...state,
      getStaff_Member: action.payload,
    };
  }
  if (action.type === GET_ALL_STAFF_MEMBER) {
    return {
      ...state,
      getAll_StaffMember: action.payload,
    };
  }
  if (action.type === GET_STAFF_MEMBER_WORKING_HOURS) {
    return {
      ...state,
      staffMemberHours: action.payload,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }

  return state;
}
