import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import AuthGuard from "../services/AuthGuard";
import CustomerCheckInLayout from "../layout/CustomerCheckInLayout";

const CheckInPage = Loadable(
  lazy(() => import("../pages/customerCheckInPage/CheckInPage"))
);

const CustomerCheckInRoutes = {
  path: "/",
  element: (
    <AuthGuard auth={true}>
      <CustomerCheckInLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "customer-check-in",
      element: <CheckInPage />,
    },
  ],
};

export default CustomerCheckInRoutes;
