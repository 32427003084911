import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_PAYROLL_DATA,
  GET_PAYROLL_STAFF_DATA,
} from "../actions/Payroll-action/PayrollAction";

const initialState = {
  getListPayroll: [],
  getStaffPr: [],
};
export function PayrollReducers(state = initialState, action) {
  if (action.type === GET_PAYROLL_DATA) {
    return {
      ...state,
      getListPayroll: action.payload,
    };
  }
  if (action.type === GET_PAYROLL_STAFF_DATA) {
    return {
      ...state,
      getStaffPr: action.payload,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }

  return state;
}
