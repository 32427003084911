import React, { createContext, useContext, useState, useEffect } from 'react';
import {  State } from 'country-state-city';

const CountryContext = createContext();

export const useCountry = () => useContext(CountryContext);


export const CountryProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  return (
    <CountryContext.Provider value={{ selectedCountry, setSelectedCountry, states }}>
      {children}
    </CountryContext.Provider>
  );
};
