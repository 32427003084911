import axios from "axios";
import { apiUrl, authApi } from "./AxiosInstance";

export function signUp(userCredential) {

  return axios.post(`${apiUrl}/api/register`, userCredential);
}

export function login(userCredential) {
  return axios.post(`${apiUrl}/api/login`, userCredential);
}

export function getUserProfile(id) {
  return authApi.get(`/api/get_profile/${id}`);
}

export function otpVerify(userCredential) {
  return authApi.post(`/api/register`,userCredential);
}

export function checkoutTrigger(data) {
  return authApi.post(`/api/check_out`,data);
}

export function Forget(userCredential) {
  return axios.post(`${apiUrl}/api/forgot_password`, userCredential);
}

export function resetPassword(userCredential) {
  return axios.post(`${apiUrl}/api/reset_password`, userCredential);
}