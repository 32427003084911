import { authApi } from "./AxiosInstance";

export function getPayroll(businessId, payrollData) {
  return authApi.post(`/api/business/getPayroll/${businessId}`, payrollData);
}

export function getPayrollStaffContent(staffId, payrollStaffData) {
  return authApi.post(
    `/api/business/getStaffPayroll/${staffId}`,
    payrollStaffData
  );
}
