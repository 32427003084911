// // third-party
// import { configureStore, combineReducers, compose } from "@reduxjs/toolkit";
// import { AuthReducer } from "./reducers/AuthReducers";
// import { BusinessSetupReducers } from "./reducers/BusinessSetupReducers";
// import { StaffMemberReducers } from "./reducers/StaffMemberReducers";
// import { CategoryReducers } from "./reducers/CategoryReducers";
// import { CalendarReducers } from "./reducers/CalendarReducers";
// import { thunk } from "redux-thunk";
// import { applyMiddleware } from "redux";
// import { ClientReducers } from "./reducers/ClientReducers";
// import { StaffCheckInReducers } from "./reducers/StaffCheckInReducers";
// import { persistStore } from "redux-persist";
// // Redux DevTools setup
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// // Combine reducers
// const reducers = combineReducers({
//   auth: AuthReducer,
//   bsReducer: BusinessSetupReducers,
//   smReducers: StaffMemberReducers,
//   categoryReducers: CategoryReducers,
//   CalendarReducers: CalendarReducers,
//   ClientReducers: ClientReducers,
//   StaffCheckInReducers: StaffCheckInReducers,
// });
// // Configure the store
// export const store = configureStore({
//   reducer: reducers,

//   devTools: composeEnhancers(applyMiddleware(thunk)),
// });
// export const persistor = persistStore(store);

// third-party
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { AuthReducer } from "./reducers/AuthReducers";
import { BusinessSetupReducers } from "./reducers/BusinessSetupReducers";
import { StaffMemberReducers } from "./reducers/StaffMemberReducers";
import { CategoryReducers } from "./reducers/CategoryReducers";
import { CalendarReducers } from "./reducers/CalendarReducers";
import { thunk } from "redux-thunk"; // Updated import
import { ClientReducers } from "./reducers/ClientReducers";
import { StaffCheckInReducers } from "./reducers/StaffCheckInReducers";
import { persistStore } from "redux-persist";
import { PayrollReducers } from "./reducers/PayrollReducers";

// Combine reducers
const reducers = combineReducers({
  auth: AuthReducer,
  bsReducer: BusinessSetupReducers,
  smReducers: StaffMemberReducers,
  categoryReducers: CategoryReducers,
  CalendarReducers: CalendarReducers,
  ClientReducers: ClientReducers,
  StaffCheckInReducers: StaffCheckInReducers,
  PayrollReducers: PayrollReducers,
});

// Configure the store
export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredPaths: ["register", "rehydrate"],
      },
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
