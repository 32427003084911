import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_APPOINTMENTS_LIST,
  GET_APPOINTMENT_BY_STATUS,
  GET_SINGLE_APPOINTMENT,
  ERROR_HANDLE_REDIRECTION,
  GET_BUSINESS_VISIBLE_HOURS,
  GET_STAFF_WEEK_DATA,
  TOOLTIPCALENDARCLICK,
  HANDLE_CREATE_AND_UPDATE_ERR,
  GET_APPOINTMENTS_CHECKOUT_LIST,
  GET_APPOINTMENT_CLIENT_DATA,
} from "../actions/Calendar-action/CalendarActions";

const initialState = {
  getsAllAppointmentsByStatus: [],
  getAppointmentList: [],
  appointmentGet: [],
  checkInRedirectHandle: "",
  getVisibleHours: [],
  getWeekStaff: [],
  isDisabledCheckIn: false,
  getCreateandUpdateErr: {
    status: "",
    message: "",
  },
  checkoutClientData: [],
};

export function CalendarReducers(state = initialState, action) {
  if (action.type === GET_APPOINTMENT_BY_STATUS) {
    return {
      ...state,
      getsAllAppointmentsByStatus: action.payload,
    };
  }
  if (action.type === GET_APPOINTMENTS_LIST) {
    return {
      ...state,
      getAppointmentList: action.payload,
    };
  }
  if (action.type === GET_STAFF_WEEK_DATA) {
    return {
      ...state,
      getWeekStaff: action.payload,
    };
  }
  if (action.type === GET_SINGLE_APPOINTMENT) {
    return {
      ...state,
      appointmentGet: action.payload,
    };
  }
  if (action.type === ERROR_HANDLE_REDIRECTION) {
    return {
      ...state,
      appointmentGet: action.payload,
    };
  }
  if (action.type === GET_BUSINESS_VISIBLE_HOURS) {
    return {
      ...state,
      getVisibleHours: action.payload,
    };
  }
  if (action.type === TOOLTIPCALENDARCLICK) {
    return {
      ...state,
      isDisabledCheckIn: action.payload,
    };
  }
  if (action.type === HANDLE_CREATE_AND_UPDATE_ERR) {
    return {
      ...state,
      getCreateandUpdateErr: {
        status: action.payload.status,
        message: action.payload.message,
      },
    };
  }
  if (action.type === GET_APPOINTMENTS_CHECKOUT_LIST) {
    return {
      ...state,
      getAppointmentList: action.payload,
    };
  }
  if (action.type === GET_APPOINTMENT_CLIENT_DATA) {
    return {
      ...state,
      checkoutClientData: action.payload,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }
  return state;
}
