import moment from "moment";
import {
  createAppointment,
  CreateAppointmentCheckinCase,
  appointmentsList,
  getAppointmentsByStatusService,
  getSingleAppointment,
  updateAppointment,
  updateCheckinAppointment,
  updateAppointmentQueueWating,
  calenderCheckInAppointmentStatus,
  appointmentDeleteById,
  calendarAppointmentServiceDelete,
  updateSpecificAppointmentService,
  visibleWorkingHours,
  sendWorkingHours,
  appointmentCheckoutList,
  appointmentCheckout,
  updateService,
  getAppointmentClient,
} from "../../../services/CalendarServices";
import {
  handlingFailedToAuthenticate,
  loaderConfirm,
  snackbarAction,
} from "../Authentication/AuthActions";
import { getStaffWeekData } from "../../../services/CategoryServices";
export const GET_APPOINTMENT_BY_STATUS =
  "[get appointment by status] get appointment by status";
export const GET_APPOINTMENTS_LIST =
  "[get appointments list] get appointments list";

export const GET_APPOINTMENTS_CHECKOUT_LIST =
  "[get appointments checkout list] get appointments checkout list";
export const GET_SINGLE_APPOINTMENT =
  "[get single appointment] get single appointment";

export const ERROR_HANDLE_REDIRECTION =
  "[get single appointment error handle] get single appointment error handle";
export const GET_BUSINESS_VISIBLE_HOURS =
  "[get business visible hours] get business visible hours";
export const GET_STAFF_WEEK_DATA = "[get staff week data] get staff week data";
export const TOOLTIPCALENDARCLICK =
  "[tooltip calendar click] tooltip calendar click";

export const HANDLE_CREATE_AND_UPDATE_ERR =
  "[handle create and update err] handle create and update err";

export const GET_APPOINTMENT_CLIENT_DATA =
  "[get appointment client] get appointment client";

export function createNewAppointment(
  data,
  checkin = "",
  selectedDate,
  navigate
) {
  const modifyDate = new Date(selectedDate);
  const formattedDate = moment(modifyDate).format("YYYY-MM-DD");
  const appointmentData = {
    onlineBooking: true,
    date: formattedDate,
    currentDate: moment().local().format("YYYY-MM-DD"),
  };

  return (dispatch) => {
    dispatch(loaderConfirm("createAppointment"));
    createAppointment(data)
      .then((response) => {
        const { data, status, code, message } = response?.data;
        let sendMessage = { code: 200, message: message };
        if (status === "error") {
          dispatch(handleCreateandUpdateErr({ status, message }));

          // dispatch(handleRedirection(status));
          dispatch(snackbarAction(true, sendMessage));
          handlingFailedToAuthenticate(code, navigate);
          dispatch(loaderConfirm(""));
        } else if (status === "success") {
          let sendMessages = { code: 200, message: "Checked In successfully" };
          if (checkin === "week") {
            const weekAppointmentData = {
              businessId: data?.[0]?.businessId,
              onlineBooking: true,
              date: formattedDate,
              staffId: data?.[0]?.bookingServiceRecord?.[0]?.staffId,
            };
            dispatch(staffWeekData(weekAppointmentData, navigate));
            dispatch(handleCreateandUpdateErr({ status, message }));
          } else {
            dispatch(
              getAppointmentsList(
                data?.[0]?.businessId,
                appointmentData,
                navigate
              )
            );
            dispatch(handleCreateandUpdateErr({ status, message }));
          }
          if (checkin === "checkin") {
            dispatch(snackbarAction(true, sendMessages));
          } else if (checkin === "waiting") {
            sendMessage.message =
              "you have been checkin successfully, now our staff will assign you to a technician";
            dispatch(snackbarAction(true, sendMessage));
          } else {
            dispatch(snackbarAction(true, sendMessage));
          }
          dispatch(loaderConfirm(""));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
      });
  };
}

export async function createNewAppointmentFromCheckin(data) {
  return CreateAppointmentCheckinCase(data)
    .then((response) => {
      const { data, status, code, message } = response?.data;
      return { data, status, code, message };
    })
    .catch((error) => {
      return error;
    });
}

export function getAppointmentByStatus(
  businessId,
  onlineBooking,
  status,
  navigate
) {
  return (dispatch) => {
    dispatch(loaderConfirm("getAppointmentByStatus"));

    const date = moment().format("YYYY-MM-DD");
    getAppointmentsByStatusService(businessId, onlineBooking, status, date)
      .then((response) => {
        const { status, code, data, message } = response?.data;
        console.log(
          "getAppointmentsByStatus status, code, data, message",
          status,
          code,
          data,
          message
        );
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(appointmentByStatus(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getAppointmentsList(businessId, apppointmentData, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getAppointment"));
    appointmentsList(businessId, apppointmentData)
      .then((response) => {
        const { status, code, data } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(allAppointment(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function staffWeekData(weekStaffData, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getStaffWeek"));
    getStaffWeekData(weekStaffData)
      .then((response) => {
        const { status, code, data } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getWeekData(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderConfirm(""));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getAppointmemt(id, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getSingleAppointment"));

    getSingleAppointment(id)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAppointmentData(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function updateAppointmentService(data, navigate) {
  return (dispatch) => {
    updateService(data)
      .then((response) => {
        const { status, code, data } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          console.log(data?.[0]?.appoitementId, "affsdfdsf");

          dispatch(getAppointmemt(data?.[0]?.appoitementId, navigate));
        }
        // dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        snackbarAction(true, message);
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function appointmentCheckoutDetails(data, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("appointmentCheckoutDetails"));
    appointmentCheckout(data)
      .then((response) => {
        const { status, code, message } = response?.data;
        let sendMessage = { code: 200, message: message };

        if (status === "error") {
          dispatch(snackbarAction(true, sendMessage));
          handlingFailedToAuthenticate(code, navigate);
          dispatch(loaderConfirm(""));
        } else if (status === "success") {
          let sendMessages = { code: 200, message: "Checkout successfully" };
          dispatch(snackbarAction(true, sendMessages));
          navigate("/payroll");
          dispatch(loaderConfirm(""));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
      });
  };
}

export function getAppointmentsCheckoutList(businessId, data, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getAppointment"));
    appointmentCheckoutList(businessId, data)
      .then((response) => {
        const { status, code, data } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(allAppointmentCheckoyList(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function appointmentUpdate(
  appointmentData,
  updatedSelectedDate,
  getWeekSelectedDate,
  weekData,
  navigate
) {
  const formattedDate = moment(updatedSelectedDate).format("YYYY-MM-DD");
  const currentDate = {
    onlineBooking: true,
    date: formattedDate,
    currentDate: moment().local().format("YYYY-MM-DD"),
  };
  return (dispatch) => {
    updateAppointment(appointmentData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          dispatch(handleCreateandUpdateErr({ status, message }));
          handlingFailedToAuthenticate(code, navigate);
        } else if (status === "success") {
          if (weekData === "week") {
            const weekAppointmentData = {
              businessId: data?.appointment?.businessId,
              onlineBooking: true,
              date: moment(getWeekSelectedDate).format("YYYY-MM-DD"),
              staffId: data?.bookingServiceRecord?.[0]?.staffId,
            };
            dispatch(staffWeekData(weekAppointmentData, navigate));
            dispatch(handleCreateandUpdateErr({ status, message }));
          } else {
            dispatch(
              getAppointmentsList(
                data?.appointment?.businessId,
                currentDate,
                navigate
              )
            );
            dispatch(handleCreateandUpdateErr({ status, message }));
          }
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export async function appointmentCheckinUpdate(appointmentData) {
  return updateCheckinAppointment(appointmentData)
    .then((response) => {
      const { data, status, code, message } = response?.data;
      return { data, status, code, message };
    })
    .catch((error) => {
      return error;
    });
}

export function appointmentUpdateFromWaiting(appointmentData, navigate) {
  const modifyDate = new Date(new Date());

  const formattedDate = moment(modifyDate).format("YYYY-MM-DD");
  const currentDate = {
    onlineBooking: true,
    date: formattedDate,
    currentDate: moment().local().format("YYYY-MM-DD"),
  };
  return (dispatch) => {
    updateAppointmentQueueWating(appointmentData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(
            getAppointmentsList(
              data?.appointment?.businessId,
              currentDate,
              navigate
            )
          );
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function calenderCheckInAppointmentCheckin(appointmentData, navigate) {
  const modifyDate = new Date(new Date());

  const formattedDate = moment(modifyDate).format("YYYY-MM-DD");
  const currentDate = {
    onlineBooking: true,
    date: formattedDate,
    currentDate: moment().local().format("YYYY-MM-DD"),
  };
  return (dispatch) => {
    calenderCheckInAppointmentStatus(appointmentData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(
            getAppointmentsList(
              data?.appointment?.businessId,
              currentDate,
              navigate
            )
          );
          dispatch(tooltipCalendarClickOneTime(false));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function deleteAppointment(appointmentId, checkin, staffId, navigate) {
  const modifyDate = new Date(new Date());
  const formattedDate = moment(modifyDate).format("YYYY-MM-DD");
  const appointmentData = {
    onlineBooking: true,
    date: formattedDate,
  };
  return (dispatch) => {
    appointmentDeleteById(appointmentId)
      .then((response) => {
        if (response.data.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
          if (checkin === "week") {
            const weekAppointmentData = {
              businessId: response.data?.data?.businessId,
              onlineBooking: true,
              date: formattedDate,
              staffId: staffId,
            };
            dispatch(staffWeekData(weekAppointmentData, navigate));
          } else {
            dispatch(
              getAppointmentsList(
                response?.data?.data?.businessId,
                appointmentData,
                navigate
              )
            );
          }
          const date = moment().format("YYYY-MM-DD");
          dispatch(
            getAppointmentByStatus(
              response?.data?.data?.businessId,
              false,
              ["waiting", "inservice"],
              date
            )
          );
        }
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorservicedelete-d", error);
      });
  };
}

export function deleteCalendarAppointmentService(serviceId, navigate) {
  return (dispatch) => {
    calendarAppointmentServiceDelete(serviceId)
      .then((response) => {
        if (response.data.status === "error") {
          console.log("error", response.data);
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
        }
        let message = {
          code: 200,
          message: response?.data?.message,
        };

        dispatch(getAppointmemt(response?.data?.data?.appoitementId, navigate));
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorservicedelete-d", error);
      });
  };
}

export function createVisibleHours(hoursData, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("createAppointment"));
    sendWorkingHours(hoursData)
      .then((response) => {
        const { status, code, message, data } = response?.data;
        let sendMessage = { code: 200, message: message };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
          dispatch(loaderConfirm(""));
        } else {
          dispatch(visibleBusinessServiceHours(hoursData.businessId, navigate));
          localStorage.setItem("closeTime", data?.[0]?.to);
          dispatch(loaderConfirm(""));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
      });
  };
}

export function allAppointment(data) {
  return {
    type: GET_APPOINTMENTS_LIST,
    payload: data,
  };
}
export function allAppointmentCheckoyList(data) {
  return {
    type: GET_APPOINTMENTS_CHECKOUT_LIST,
    payload: data,
  };
}

export function updateSpecificService(appointmentData, navigate) {
  return (dispatch) => {
    updateSpecificAppointmentService(appointmentData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          console.log(data, "dataspecific");
          // dispatch(getAppointmemt(id,navigate));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function visibleBusinessServiceHours(businessid, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getBusinessVisibleHours"));
    visibleWorkingHours(businessid)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getWorkingVisibleHours(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function getClientAppoint(clientdata, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getBusinessVisibleHours"));
    getAppointmentClient(clientdata)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAppointmentClientData(data));
          // dispatch(getWorkingVisibleHours(data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetcategoryAndService", error);
      });
  };
}

export function appointmentByStatus(data) {
  return {
    type: GET_APPOINTMENT_BY_STATUS,
    payload: data,
  };
}

export function getWeekData(data) {
  return {
    type: GET_STAFF_WEEK_DATA,
    payload: data,
  };
}

export function getAppointmentData(data) {
  return {
    type: GET_SINGLE_APPOINTMENT,
    payload: data,
  };
}
export function errorHandleAppoitment(data) {
  return {
    type: ERROR_HANDLE_REDIRECTION,
    payload: data,
  };
}
export function getWorkingVisibleHours(data) {
  return {
    type: GET_BUSINESS_VISIBLE_HOURS,
    payload: data,
  };
}

export function tooltipCalendarClickOneTime(data) {
  return {
    type: TOOLTIPCALENDARCLICK,
    payload: data,
  };
}

export function handleCreateandUpdateErr(data) {
  return {
    type: HANDLE_CREATE_AND_UPDATE_ERR,
    payload: data,
  };
}

export function getAppointmentClientData(data) {
  return {
    type: GET_APPOINTMENT_CLIENT_DATA,
    payload: data,
  };
}
