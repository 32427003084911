import { authApi } from "./AxiosInstance";
import moment from "moment";
export function addStaffMember(staffData) {
  return authApi.post("/api/add_staff", staffData);
}

export function updateStaffMember(staffData) {
  return authApi.post("/api/edit_staff", staffData);
}

export function getStaffMember(id) {
  return authApi.get(`/api/get_staff/${id}`);
}

export function getAllStaffMember(businessId) {
  return authApi.get(`/api/get_all_staff/${businessId}`);
}

export function uploadStaffMemberImg(id, staffImg) {
  return authApi.post(`/api/upload_profile_image/${id}`, staffImg);
}

export function deleteStaffMember(id) {
  return authApi.post(`/api/delete_staff/${id}`, {
    currentTime: moment().format("YYYY-MM-DD"),
  });
}

export function changeStaffPassword(passwordData) {
  return authApi.post(`/api/change_password`, passwordData);
}

export function getStaffMemberWorkingHours(staffId, businessId) {
  return authApi.get(`/api/staff-working-hours/${staffId}/${businessId}`);
}

export function updateStaffMemberWorkingHours(staffHoursData) {
  return authApi.post(`/api/staff-working-hours`, staffHoursData);
}
