import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./pages/Error/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Utility function to detect Chrome
const isChrome = () =>
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

if (isSafari()) {
  document.body.classList.add("safari-browser");
} else if (isChrome()) {
  document.body.classList.add("chrome-browser");
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
