import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {} from "../actions/Calendar-action/CalendarActions";
import {
  GET_ALL_CLIENT,
  GET_ALL_CLIENT_WITH_APP_ID,
  GET_SINGLE_CLIENT,
  HANDLE_CLIENT_ERROR,
} from "../actions/Client-action/ClientActions";

const initialState = {
  clientLists: [],
  getClientInfo: [],
  getClientErr: {
    status: "",
    message: "",
  },
  getClientListWithId: [],
};

export function ClientReducers(state = initialState, action) {
  if (action.type === GET_ALL_CLIENT) {
    return {
      ...state,
      clientLists: action.payload,
    };
  }
  if (action.type === GET_ALL_CLIENT_WITH_APP_ID) {
    return {
      ...state,
      getClientListWithId: action.payload,
    };
  }
  if (action.type === GET_SINGLE_CLIENT) {
    return {
      ...state,
      getClientInfo: action.payload,
    };
  }
  if (action.type === HANDLE_CLIENT_ERROR) {
    return {
      ...state,
      getClientErr: {
        status: action.payload.status,
        message: action.payload.message,
      },
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }

  return state;
}
