import { authApi } from "./AxiosInstance";

export function getUserAppointment(appointmentId) {
  return authApi.post(`/api/appoitment/${appointmentId}`);
}

export function createAppointment(appointmentData) {
  return authApi.post("/api/appoitment/add", appointmentData);
}

export function CreateAppointmentCheckinCase(appointmentData) {
  return authApi.post("/api/appoitment/add_checkin_case", appointmentData);
}

export function getSingleAppointment(id) {
  return authApi.get(`/api/appoitment/getappoitement/${id}`);
}

export function appointmentCheckout(data) {
  return authApi.post(`/api/appoitment/checkout_appoitment`, data);
}

export function updateService(data) {
  console.log(data, "route");

  return authApi.post(`/api/appoitment/update`, data);
}

export function updateAppointment(appointmentData) {
  return authApi.post(`/api/appoitment/appoitementservice`, appointmentData);
}

export function updateCheckinAppointment(appointmentData) {
  return authApi.post(
    `/api/appoitment/checkin/update_appoitment`,
    appointmentData
  );
}
export function updateAppointmentQueueWating(appointmentData) {
  return authApi.post(
    `/api/appoitment/checkin/update_appoitment_queue_waiting`,
    appointmentData
  );
}

export function calenderCheckInAppointmentStatus(appointmentData) {
  return authApi.post(
    `/api/appoitment/checkin/calender_checkIn_appointment_status`,
    appointmentData
  );
}

export function getAppointmentsByStatusService(
  businessId,
  onlineBooking,
  status,
  date
) {
  const sendStatus = {
    status: status,
    onlineBooking: onlineBooking ? onlineBooking : false,
    date,
  };
  console.log("send", sendStatus);
  return authApi.post(
    `/api/appoitment/getappointementbystatus/${businessId}`,
    sendStatus
  );
}

export function appointmentsList(businessId, apppointmentData) {
  return authApi.post(`/api/appoitment/list/${businessId}`, apppointmentData);
}

export function calendarAppointmentServiceDelete(serviceId) {
  return authApi.post(`/api/appoitment/servicedelete/${serviceId}`);
}

export function appointmentDeleteById(appointmentId) {
  return authApi.post(`/api/appoitment/delete/${appointmentId}`);
}

export function updateSpecificAppointmentService(specificAppointmentData) {
  return authApi.post(`/api/appointment/update`, specificAppointmentData);
}

export function visibleWorkingHours(businessId) {
  return authApi.get(`/api/business/working-hours/${businessId}`);
}

export function sendWorkingHours(hoursData) {
  return authApi.post(`/api/business/working-hours`, hoursData);
}

export function appointmentCheckoutList(businessId, staffIds) {
  return authApi.post(`/api/appoitment/checkout/${businessId}`, staffIds);
}

export function getAppointmentClient(clientData) {
  return authApi.post(`/api/appoitment/getClientAppointment`, clientData);
}
