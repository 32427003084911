import { authApi } from "./AxiosInstance";

export function createNewClient(clientData) {
  return authApi.post(`/api/client/create`, clientData);
}

export function updateNewClient(clientId, clientData) {
  return authApi.post(`/api/client/update/${clientId}`, clientData);
}

export function getSingleClient(clientId) {
  return authApi.get(`/api/client/getclient/${clientId}`);
}

export function getClientList(businessId) {
  return authApi.get(`/api/client/list/${businessId}`);
}

export function getClientWithAppId(businessId, bookingDate) {
  return authApi.get(
    `/api/client-appointment/list/${businessId}?bookingDate=${bookingDate}`
  );
}
