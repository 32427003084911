import {
  addBusinessLocation,
  addServiceDeduction,
  createBusiness,
  deleteBusinessLocation,
  deleteQrCode,
  editBusinessSetting,
  getBusiness,
  getServiceDeductionData,
  paymentQrCode,
  removeCoverAndLogoImage,
  updateBusiness,
  updateBusinessLocation,
  uploadCoverAndLogoImage,
} from "../../../services/BusinessSetupService";
import {
  handlingFailedToAuthenticate,
  saveUserDetails,
  snackbarAction,
} from "../Authentication/AuthActions";
import { getCommonResponse } from "../Category/CategoryActions";
export const UPLOAD_COVER_AND_LOGO_IMAGE =
  "[upload cover and logo image] upload cover and logo image";
export const CREATE_NEW_BUSINESS = "[create new business] create new business";
export const GET_NEW_BUSINESS = "[get new business] get new business";
export const GET_LOCATION_STATUS = "[get location status] get location status";
export const GET_MODAL_STATUS = "[get modal status] get modal status";
export const GET_VIRTUAL_STEPS = "[get virtual steps] get virtual steps";
export const RELOAD_GET_BUSINESS_API =
  "[reload get business api] reload get business api";
export const GET_SERVICE_DEDUCTION_DATA =
  "[get service deduction data] get service deduction data";

export function getNewBusiness(id, navigate) {
  return (dispatch) => {
    getBusiness(id)
      .then((response) => {
        if (response.data.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
          dispatch(get_New_Business(response?.data?.data));
          let userToken = JSON.parse(localStorage.getItem("access_token"));
          let userId = JSON.parse(localStorage.getItem("userId"));
          if (userToken && userId) {
            dispatch(saveUserDetails(userId, navigate));
          }
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-u", error);
      });
  };
}

export function setPaymentQrCode(businessId, data, navigate) {
  return (dispatch) => {
    console.log(data);

    paymentQrCode(businessId, data)
      .then((response) => {
        if (response) {
          let userId = JSON.parse(localStorage.getItem("userId"));
          if (userId) {
            dispatch(saveUserDetails(userId, navigate));
          }
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-u", error);
      });
  };
}

export function reomvePaymentQrCode(data, navigate) {
  return (dispatch) => {
    deleteQrCode(data)
      .then((response) => {
        let userId = JSON.parse(localStorage.getItem("userId"));
        if (userId) {
          dispatch(saveUserDetails(userId, navigate));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-u", error);
      });
  };
}

export function createNewBusiness(userData, navigate) {
  return (dispatch) => {
    createBusiness(userData)
      .then((response) => {
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
        navigate("/");
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-c", error);
      });
  };
}

export function updateNewBusiness(userData, navigate) {
  return (dispatch) => {
    updateBusiness(userData)
      .then((response) => {
        if (response.data.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);
        } else {
          dispatch(getNewBusiness(userData?.businessId, navigate));
        }
        let message = {
          code: 200,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-u", error);
      });
  };
}

export function uploadCoverAndLogo(content, navigate) {
  const formData = new FormData();
  formData.append("image", content.image);
  formData.append("imageType", content.imageType);
  formData.append("userId", content.userId);
  formData.append("businessId", content.businessId);

  return (dispatch) => {
    uploadCoverAndLogoImage(formData)
      .then((response) => {
        const { status, data, message, code } = response?.data;

        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getNewBusiness(data?.[0]?._id), navigate);
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorImage", error);
      });
  };
}

export function removeUploadCoverAndLogoImage(content, navigate) {
  return (dispatch) => {
    removeCoverAndLogoImage(content)
      .then((response) => {
        const { status, message, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getNewBusiness(content?.businessId), navigate);
        }
        dispatch(snackbarAction(true, sendMessage));
      })

      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorImage", error);
      });
  };
}

export function addnewBusinessLocation(locationData, navigate) {
  return (dispatch) => {
    addBusinessLocation(locationData)
      .then((response) => {
        const { status, message, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getLocationStatus(status));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusinesslocation-c", error);
      });
  };
}

export function updateBusinessLocations(locationData, navigate) {
  return (dispatch) => {
    updateBusinessLocation(locationData)
      .then((response) => {
        const { status, data, message, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getNewBusiness(data?.[0]?._id), navigate);
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusinesslocation-u", error);
      });
  };
}

export function deleteBusinessLocations(locationData, navigate) {
  return (dispatch) => {
    deleteBusinessLocation(locationData)
      .then((response) => {
        const { status, data, message, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getNewBusiness(data?.[0]?._id), navigate);
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusinesslocation-d", error);
      });
  };
}

export function editBusinessSettings(settingdata, navigate) {
  return (dispatch) => {
    editBusinessSetting(settingdata)
      .then((response) => {
        const { status, message, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
          dispatch(snackbarAction(true, sendMessage));
        } else {
          dispatch(getCommonResponse(status));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("editBusinessSettings", error);
      });
  };
}

export function addNewServiceDeductions(serviceDeductionData, navigate) {
  return (dispatch) => {
    addServiceDeduction(serviceDeductionData)
      .then((response) => {
        const { status, message, code, data } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
          dispatch(snackbarAction(true, sendMessage));
        } else if (status === "success") {
          dispatch(getNewServiceDeduction(data?.businessId, navigate));
          dispatch(snackbarAction(true, sendMessage));
          // dispatch(getCommonResponse(status));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-c", error);
      });
  };
}

export function getNewServiceDeduction(businessId, navigate) {
  return (dispatch) => {
    getServiceDeductionData(businessId)
      .then((response) => {
        const { status, message, code, data } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
          dispatch(snackbarAction(true, sendMessage));
        } else {
          dispatch(getSerDeductionData(data));
          // dispatch(snackbarAction(true, sendMessage));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorbusiness-c", error);
      });
  };
}
export function get_New_Business(data) {
  return {
    type: GET_NEW_BUSINESS,
    payload: data,
  };
}
export function getLocationStatus(data) {
  return {
    type: GET_LOCATION_STATUS,
    payload: data,
  };
}

export function getLocationModalStatus(data) {
  return {
    type: GET_MODAL_STATUS,
    payload: data,
  };
}
export function getVirtualLocatiionSteps(data) {
  return {
    type: GET_VIRTUAL_STEPS,
    payload: data,
  };
}

export function reloadGetBusinessApi(data) {
  return {
    type: RELOAD_GET_BUSINESS_API,
    payload: data,
  };
}

export function getSerDeductionData(data) {
  return {
    type: GET_SERVICE_DEDUCTION_DATA,
    payload: data,
  };
}
