import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  ListItemButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import businessIcon from "../../assets/icons/business-setup/business-details.svg";
import locationIcon from "../../assets/icons/business-setup/location.svg";
import phoneIcon from "../../assets/icons/business-setup/phone_number.svg";
import brandingIcon from "../../assets/icons/business-setup/branding.svg";
import settingsIcon from "../../assets/icons/business-setup/advancedSetting.svg";

const items = [
  {
    label: "Business Details",
    value: "1",
    icon: businessIcon,
    path: "/settings/business-setup/business-details",
  },
  {
    label: "Locations",
    value: "2",
    icon: locationIcon,
    path: "/settings/business-setup/locations",
  },
  {
    label: "Phone Numbers",
    value: "3",
    icon: phoneIcon,
    path: "/settings/business-setup/phone-numbers",
  },
  {
    label: "Logo & Branding",
    value: "4",
    icon: brandingIcon,
    path: "/settings/business-setup/logo-branding",
  },

  {
    label: "Advanced Settings",
    value: "5",
    icon: settingsIcon,
    path: "/settings/business-setup/advanced",
  },
];
function TabsContent({ value, handleChange, handleTabClick, handleClose }) {
  const theme = useTheme();

  return (
    <List
      sx={{
        "& .Mui-selected": {
          backgroundImage:
            "linear-gradient(to right, rgba(192, 193, 227, 0.1) 90%, #1F234A 97%)",
          color: theme.palette.primary.contrastText,
          fontWeight: 600,
          "& .bs-sidebar-icons": {
            filter: "invert(10) brightness(0.5)",
          },
        },
        paddingTop: "0px !important",
      }}
    >
      {items.map((item) => (
        <React.Fragment key={item.value}>
          {item.value === "5" && (
            <Divider
              sx={{
                marginBlock: "20px",
                borderColor: "#c0c1e3",
                marginLeft: "8px !important",
                marginRight: "8px !important",
              }}
            />
          )}
          <ListItemButton
            selected={value === item.value}
            onClick={(event) => {
              handleChange(event, item.value);
              handleTabClick(item.path);
              handleClose();
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <img
                src={item.icon}
                alt={`${item.label} Icon`}
                className="bs-sidebar-icons"
              />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </React.Fragment>
      ))}
    </List>
  );
}

function BusinessSetUpSidebar({ handleClose }) {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const path = location?.pathname;
    const matchedItem = items.find((item) => item.path === path);
    setValue(matchedItem ? matchedItem.value : "1");
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#414574",
        textTransform: "capitalize !important",
        color: "#c0c1e3",
        height: "100%",
      }}
    >
      <TabsContent
        value={value}
        handleChange={handleChange}
        handleTabClick={handleTabClick}
        handleClose={handleClose}
      />
    </Box>
  );
}

export default BusinessSetUpSidebar;
