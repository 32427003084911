import { lazy } from "react";
import Loadable from "../components/Loadable";
import AuthGuard from "../services/AuthGuard";
import CalendarAndAppointmentLayout from "../layout/CalendarAndAppointmentLayout";

// Settings Route

const DisplayPreferenceDetails = Loadable(
  lazy(() =>
    import(
      "../pages/settings/CalendarAndAppointment/DisplayPerference/DisplayPreference"
    )
  )
);

// ==============================|| MAIN ROUTING ||============================== //

const AppointmentsAndCalendarRoutes = {
  path: "settings/calendar-appointments/",
  element: (
    <AuthGuard auth={true}>
      <CalendarAndAppointmentLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: "business-details",
    //   element: (
    //     <CountryProvider>
    //       <BusinessDetails />
    //     </CountryProvider>
    //   ),
    // },
    // {
    //   path: "locations",
    //   element: (
    //     <CountryProvider>
    //       <LocationDetails />
    //     </CountryProvider>
    //   ),
    // },
    {
      path: "display-preferences",
      element: <DisplayPreferenceDetails />,
    },
    // {
    //   path: "logo-branding",
    //   element: <BrandAndLogo />,
    // },
    // {
    //   path: "advanced",
    //   element: <AdvancedSettings />,
    // },
  ],
};

export default AppointmentsAndCalendarRoutes;
