import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout/index";
import AuthGuard from "../services/AuthGuard";

const Login = Loadable(
  lazy(() => import("../pages/authentication/Login/Login"))
);

const Otp = Loadable(
  lazy(() => import("../pages/authentication/Otp/otp"))
);
// const PreLogin = Loadable(
//   lazy(() => import("pages/authentication/PreLogin/PreLogin"))
// );
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register/Register"))
);
const CheckInPage = Loadable(
  lazy(() => import("../pages/checkin/CheckInPage"))
);

const Forget = Loadable(
  lazy(() => import("../pages/authentication/Forget/forget"))
);

const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/Reset/resetPassword"))
);


const AuthRoutes = {
  path: "auth",
  element: (
    <AuthGuard auth={false}>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "otp",
      element: <Otp/>,
    },
    {
      path: "check-in",
      element: <CheckInPage />,
    },
    {
      path: "forget",
      element: <Forget/>,
    }, 
    {
      path: "resetpassword",
      element: <ResetPassword/>,
    }, 


  ],
};

export default AuthRoutes;
