import { useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import Pagenotfound from "../pages/Error/Pagenotfound";
import SettingsRoutes from "./SettingsRoutes";
import BusinessSetupRoutes from "./BusinessSetupRoutes";
import AuthRoutes from "./AuthRoutes";
// import CheckInRoutes from "./CheckInRoutes";
import CustomerCheckInRoutes from "./CustomerCheckInRoutes";
import AppointmentsAndCalendarRoutes from "./AppointmentsAndCalendarRoutes";
import PaymentsAndCheckout from "./PaymentsAndCheckout";

// ==============================|| ROUTING RENDER ||============================== //
const ThemeRoutes = () => {
  const routing = useRoutes([
    AuthRoutes,
    MainRoutes,
    SettingsRoutes,
    BusinessSetupRoutes,
    // CheckInRoutes,
    CustomerCheckInRoutes,
    AppointmentsAndCalendarRoutes,
    PaymentsAndCheckout,
    {
      path: "*",
      element: <Pagenotfound />,
    },
  ]);
  return routing;
};
export default ThemeRoutes;
