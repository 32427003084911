import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import clients from "../../../assets/icons/header/clients.svg";
import sales from "../../../assets/icons/header/sales.svg";
import calenderIcon from "../../../assets/icons/header/calender-icon.svg";
import queue from "../../../assets/icons/header/queue-icon.svg";
import frontDesk from "../../../assets/icons/header/frontdesk-icon.svg";
import customerCheckInIcon from "../../../assets/icons/header/customer-checkin-icon.svg";
import MenuIcon from "@mui/icons-material/Menu";

import setting from "../../../assets/icons/header/settings.svg";
import downArrow from "../../../assets/icons/header/downArrow.svg";

//--------------- logo Images ------------
import logoIcon from "../../../assets/icons/header/logoIcon.svg";
import logoText from "../../../assets/Images/header/SalonBookin.com.svg";

import "./Header.css";

// // Profiles-icon
import userIcon from "../../../assets/icons/header/user.svg";

import logout from "../../../assets/icons/header/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../../store/actions/Authentication/AuthActions";
import { persistor } from "../../../store/index";
const navLink = [
  { id: 1, path: "/customer-check-in", icon: frontDesk, title: "Front Desk" },
  {
    id: 2,
    path: "/check-in",
    icon: customerCheckInIcon,
    title: "Customer Check In",
  },
  { id: 3, path: "/appointments", icon: calenderIcon, title: "Calendar" },
  { id: 4, path: "/staff-check-in", icon: clients, title: "Staff Check In" },
  { id: 5, path: "/clients", icon: clients, title: "Clients" },
  { id: 6, path: "/queue", icon: queue, title: "Queue" },
  { id: 7, path: "/payroll", icon: sales, title: "Staff's Earnings" },
  { id: 8, path: "/checkout", icon: queue, title: "In-Service" },
];

const settings = [
  { id: 1, icon: userIcon, name: "User Profile" },
  { id: 2, icon: logout, name: "Log Out" },
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [visibleItems, setVisibleItems] = useState([]);
  const [moreItems, setMoreItems] = useState([]);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const firstname = user?.data?.[0]?.firstname;
  const lastname = user?.data?.[0]?.lastname;
  const businessName = user?.data?.[0]?.businessId?.name;
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const calculateVisibleItems = () => {
    const width = window.innerWidth;

    if (width >= 2400) {
      setVisibleItems(navLink);
      setMoreItems([]);
    } else {
      const baseWidth = 224 * 5;
      const extraItems = Math.floor((width - baseWidth) / 224);
      const numVisibleItems = Math.min(4 + extraItems, navLink.length);

      setVisibleItems(navLink.slice(0, numVisibleItems));
      setMoreItems(navLink.slice(numVisibleItems));
    }
  };
  useEffect(() => {
    setActiveItem(location.pathname);
    calculateVisibleItems();
    window.addEventListener("resize", calculateVisibleItems);

    return () => window.removeEventListener("resize", calculateVisibleItems);
  }, [location.pathname]);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth > 900) {
        setMobileScreen(false);
      }
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    persistor.purge();
    sessionStorage.clear();
    dispatch(Logout(navigate));
  };

  return (
    <Grid
      container
      spacing={{ xs: 0, sm: 0, md: 2.5, lg: 2.5, xl: 2.5 }}
      className="header-container"
      px={{ xs: 1, sm: 1 }}
    >
      <Grid item md={2.5} lg={2} xl={2} sm={6} xs={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "center",
              lg: "center",
              xl: "center",
            },
            paddingLeft: {
              xs: "0px !important",
              sm: "0px !important",
              md: "0px !important",
              lg: "30px !important",
              xl: "30px !important",
            },
            cursor: "pointer",
          }}
          onClick={() => navigate("/appointments")}
        >
          <Box
            sx={{
              width: {
                xs: "25px",
                sm: "30px",
                md: "38px",
                lg: "38px",
                xl: "38px",
              },
            }}
          >
            <img src={logoIcon} alt="" width="100%" />
          </Box>

          <Box
            sx={{
              width: {
                xs: "60%",
                sm: "40%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
            }}
          >
            <img src={logoText} alt="" width="100%" />
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        md={8}
        sm={7.5}
        xs={12}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "unset",
            lg: "unset",
            xl: "unset",
          },
        }}
      >
        <ul className="nav-list">
          {visibleItems.map((list) => (
            <li
              className="nav-item"
              style={{
                borderBottom:
                  activeItem === list.path ? "2px solid #00F0FF" : "",
              }}
              key={list.id}
            >
              <Link
                style={{
                  color: activeItem === list.path ? "#00F0FF" : "",

                  textDecoration: "none",
                }}
                to={list.path}
                className="nav-list-item"
              >
                <img
                  src={list.icon}
                  style={{
                    filter:
                      activeItem === list.path
                        ? "invert(50%) sepia(100%) saturate(1000%) hue-rotate(180deg)"
                        : "",
                  }}
                  alt=""
                  className="nav-icons"
                />
                <Typography variant="p" className="nav-item-title">
                  {list.title}
                </Typography>
              </Link>
            </li>
          ))}
          {moreItems.length > 0 && (
            <li className="More-text">
              <Typography
                className="more-text-with-icon"
                onClick={handleOpenNavMenu}
              >
                More <img src={downArrow} alt="" className="More-icon" />
              </Typography>

              <Menu
                id="more-menu"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ mt: "45px" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      overflow: "visible",
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: "50%",
                        width: 20,
                        height: 20,
                        backgroundColor: "inherit",
                        transform: "translateY(-50%) rotate(45deg)",
                        boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                      },
                    },
                  },
                }}
              >
                {moreItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={handleCloseNavMenu}
                    className="More-menu-list"
                  >
                    <Link to={item.path} className="more-list">
                      <img src={item.icon} alt="" className="profile-icons" />
                      <Typography variant="p">{item.title}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </li>
          )}
        </ul>
      </Grid>
      <Grid
        item
        md={1.5}
        sm={5}
        xs={5}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box
          className="setting-box"
          sx={{
            padding: {
              xs: "4px 0px !important",
              sm: "4px 0px !important",
              md: "4px 15px",
            },
          }}
          onClick={() => navigate("/settings")}
        >
          <img src={setting} className="setting-icon" alt="" />
          <Typography
            variant="p"
            className="setting-name"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "unset",
                xl: "unset",
              },
            }}
          >
            Settings
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Box
            className="profile-dropdown"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleOpenUserMenu}
          >
            <Typography
              className="names-word"
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "16px",
                  lg: "16px",
                  xl: "16px",
                },
                width: {
                  xs: "30px",
                  sm: "30px",
                  md: "36px",
                  lg: "36px",
                  xl: "36px",
                },
                height: {
                  xs: "30px",
                  sm: "30px",
                  md: "36px",
                  lg: "36px",
                  xl: "36px",
                },
              }}
            >
              {firstname?.slice(0, 2)}
            </Typography>
            <Typography
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "unset",
                  xl: "unset",
                },
              }}
              className="profile-name cprofile-ellipsis"
            >
              {firstname} {lastname && lastname} <br />
              <span className="cprofile-ellipsis">{businessName}</span>
            </Typography>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "unset",
                  lg: "unset",
                  xl: "unset",
                },
              }}
            >
              <img src={downArrow} className="down-arrow" alt="down-arrow" />
            </Box>
          </Box>
          {/* </Tooltip> */}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  overflow: "visible",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 20,
                    height: 20,
                    backgroundColor: "inherit",
                    transform: "translateY(-50%) rotate(45deg)",
                    boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                  },
                },
              },
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            className="More-menu-list"
          >
            {settings.map((setting) => (
              <MenuItem
                className="menu-list"
                key={setting.id}
                onClick={
                  setting.name === "Log Out"
                    ? handleLogout
                    : handleCloseUserMenu
                }
              >
                <img src={setting.icon} alt="" className="profile-icons" />
                <Typography textAlign="center">{setting.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        sx={{
          display: {
            xs: "unset",
            sm: "unset",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
        textAlign={{ xs: "right", sm: "center" }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon
            onClick={() => {
              setMobileScreen((prev) => !prev);
            }}
          />
        </IconButton>
      </Grid>
      {mobileScreen && (
        <Grid
          sx={{
            width: "100%",
            xs: "unset",
            sm: "unset",
            md: "none",
            lg: "none",
            xl: "none",
          }}
        >
          {navLink.map((item) => (
            <Box
              key={item.id}
              onClick={() => {
                setMobileScreen(false);
              }}
            >
              <Link to={item.path} className="more-list">
                <img src={item.icon} alt="" className="profile-icons" />
                <Typography variant="p">{item.title}</Typography>
              </Link>
            </Box>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
