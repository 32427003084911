import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_ALL_CHECKIN_ACTIVE_STAFF_LIST,
  GET_ALL_CHECKOUT_LIST,
  CHECK_IN_STATE,
} from "../actions/Staff-checkin/StaffCheckInActions";

const initialState = {
  getAll_CheckInList: [],
  checkInActiveStaff: [],
  checkInStateBool: false,
};
export function StaffCheckInReducers(state = initialState, action) {
  if (action.type === GET_ALL_CHECKOUT_LIST) {
    return {
      ...state,
      getAll_CheckInList: action.payload,
    };
  }
  if (action.type === GET_ALL_CHECKIN_ACTIVE_STAFF_LIST) {
    return {
      ...state,
      checkInActiveStaff: action.payload,
    };
  }
  if (action.type === CHECK_IN_STATE) {
    return {
      ...state,
      checkInStateBool: action.payload,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }
  return state;
}
