import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./Header/Header";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {

  return (
    <Box>
        <Box sx={{ height: "56px", width: "100%" }}>
          <Header />
        </Box>
      <Box component="main">
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
