import {
  createNewClient,
  getClientList,
  getClientWithAppId,
  getSingleClient,
  updateNewClient,
} from "../../../services/ClientServices";
import {
  handlingFailedToAuthenticate,
  snackbarAction,
} from "../Authentication/AuthActions";
export const GET_SINGLE_CLIENT = "[get single client] get single client";
export const GET_ALL_CLIENT = "[get all client] get all client";
export const GET_ALL_CLIENT_WITH_APP_ID =
  "[get all client with app id] get all client with app id";
export const HANDLE_CLIENT_ERROR = "[handle client error] handle client error";

export function createClient(clientData, clientStatus, navigate) {
  return (dispatch) => {
    createNewClient(clientData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          dispatch(handleClientError({ status, message }));

          handlingFailedToAuthenticate(code, navigate);
        } else if (status === "success") {
          if (clientStatus === "withoutReferral") {
            dispatch(getAllClient(clientData?.businessId, navigate));
          } else {
            dispatch(getClient(data?.[0]?._id, navigate));
            dispatch(handleClientError({ status, message }));
          }
          dispatch(snackbarAction(true, sendMessage));
        }
      })
      .catch((error) => {
        console.log("error", error);
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        // console.log("erroradd-client", error);
      });
  };
}

export function updateClient(clientId, clientData, navigate) {
  return (dispatch) => {
    updateNewClient(clientId, clientData)
      .then((response) => {
        const {
          // data,
          message,
          status,
          code,
        } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          dispatch(handleClientError({ status, message }));
          handlingFailedToAuthenticate(code, navigate);
        } else if (status === "success") {
          dispatch(getAllClient(clientData?.businessId, navigate));
          dispatch(handleClientError({ status, message }));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        // console.log("erroradd-client", error);
      });
  };
}

// export function createClientCheckIn(clientData, navigate) {

//   return createNewClient(clientData).then((response) => {
//     const { data, message, status, code } = response?.data;
//     let sendMessage = { code: 200, message: message };
//     if (status === "error") {
//       handlingFailedToAuthenticate(code, navigate);
//       return false;
//     } else {
//       return getSingleClient(data?.[0]?._id)
//         .then((response) => {
//           const { data, status, code } = response?.data;
//           if (status === "error") {
//             handlingFailedToAuthenticate(code, navigate);
//             return false;
//           } else {
//             console.log("Response after get client data", data);
//             return data;
//           }
//         })
//     }
//   }).catch((error) => {
//     return error;
//   });
// }

export function getClient(clientId, navigate) {
  return (dispatch) => {
    getSingleClient(clientId)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          console.log("Response after get client data", data);
          dispatch(getClientData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetclient", error);
      });
  };
}

export function getAllClient(businessId, navigate) {
  return (dispatch) => {
    getClientList(businessId)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getClientListData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetclient", error);
      });
  };
}

export function getAllClientListWithAppId(businessId, bookingDate, navigate) {
  return (dispatch) => {
    getClientWithAppId(businessId, bookingDate)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getClientWithApptId(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetclient", error);
      });
  };
}

export function getClientData(data) {
  // console.log(data, 'client data in actions', getClientData);
  return {
    type: GET_SINGLE_CLIENT,
    payload: data,
  };
}

export function getClientListData(data) {
  return {
    type: GET_ALL_CLIENT,
    payload: data,
  };
}

export function getClientWithApptId(data) {
  return {
    type: GET_ALL_CLIENT_WITH_APP_ID,
    payload: data,
  };
}

export function handleClientError(data) {
  return {
    type: HANDLE_CLIENT_ERROR,
    payload: data,
  };
}
