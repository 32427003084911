import { authApi } from "./AxiosInstance";

export function getAllCheckoutList(checkoutData) {
  return authApi.post(`/api/get_all_check_in_out`, checkoutData);
}

export function getAllCheckInActiveStaff(checkoutData) {
  return authApi.post(`/api/all_checkin_active_staff`, checkoutData);
}
export function checkInCheckOut(checkInCheckOutData) {
  return authApi.post(`/api/check_in_out`, checkInCheckOutData);
}
