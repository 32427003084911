import {
  addStaffMember,
  updateStaffMember,
  getStaffMember,
  getAllStaffMember,
  changeStaffPassword,
  uploadStaffMemberImg,
  deleteStaffMember,
  getStaffMemberWorkingHours,
  updateStaffMemberWorkingHours,
} from "../../../services/StaffMemberService";
import {
  handlingFailedToAuthenticate,
  snackbarAction,
} from "../Authentication/AuthActions";
import { getCommonResponse } from "../Category/CategoryActions";
export const GET_STAFF_MEMBER = "[get staff member] get staff member";
export const GET_ALL_STAFF_MEMBER =
  "[get all staff member] get all staff member";
export const GET_STAFF_MEMBER_WORKING_HOURS =
  "[get staff member working hours] get staff member working hours";
export function AddNewStaffMember(staffData, addStaffOnSuccess, navigate) {
  return (dispatch) => {
    addStaffMember(staffData)
      .then((response) => {
        const { data, message, status, code } = response?.data;

        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          addStaffOnSuccess(data?._id);
          dispatch(getAllStaff(data?.businessId));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("erroradd-staff", error);
      });
  };
}

export function UpdateNewStaffMember(staffData, navigate) {
  return (dispatch) => {
    updateStaffMember(staffData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getStaff(data?._id));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function deleteStaffById(staffData, businessid, navigate) {
  return (dispatch) => {
    deleteStaffMember(staffData, businessid)
      .then((response) => {
        const { message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAllStaff(businessid, navigate));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function getStaff(id, navigate) {
  return (dispatch) => {
    getStaffMember(id)
      .then((response) => {
        const { status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getStaffData(response?.data, navigate));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetStaff-staff", error);
      });
  };
}

export function getAllStaff(businessId, navigate) {
  return (dispatch) => {
    getAllStaffMember(businessId)
      .then((response) => {
        const { status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAllStaffData(response?.data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetAllStaff-staff", error);
      });
  };
}

export function staffMemberWorkingHours(staffId, businessId, navigate) {
  return (dispatch) => {
    getStaffMemberWorkingHours(staffId, businessId)
      .then((response) => {
        const { status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getStaffHours(response?.data?.data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetAllStaff-staff", error);
      });
  };
}

export function updateStaffMemberHours(staffData, navigate) {
  return (dispatch) => {
    updateStaffMemberWorkingHours(staffData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(
            staffMemberWorkingHours(
              data?.[0]?.staffId,
              data?.[0]?.businessId,
              navigate
            )
          );
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorupdate-staff", error);
      });
  };
}

export function changeStaffMemberPass(passwordData, navigate) {
  return (dispatch) => {
    changeStaffPassword(passwordData)
      .then((response) => {
        const { status, data, code, message } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAllStaff(data?.businessId));
          dispatch(getCommonResponse(data.status));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorchangeStaffMemberPass-staff", error);
      });
  };
}

export function uploadStaffProfilePic(id, content, navigate) {
  const formData = new FormData();
  formData.append("image", content.image);

  return (dispatch) => {
    uploadStaffMemberImg(id, formData)
      .then((response) => {
        const { data, message, status, code } = response?.data;
        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getAllStaff(data?.[0]?.businessId));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("uploadStaffProfilePic", error);
      });
  };
}

export function getStaffData(data) {
  return {
    type: GET_STAFF_MEMBER,
    payload: data,
  };
}

export function getAllStaffData(data) {
  return {
    type: GET_ALL_STAFF_MEMBER,
    payload: data,
  };
}

export function getStaffHours(data) {
  return {
    type: GET_STAFF_MEMBER_WORKING_HOURS,
    payload: data,
  };
}
