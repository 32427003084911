import {
  getPayroll,
  getPayrollStaffContent,
} from "../../../services/PayrollService";
import {
  handlingFailedToAuthenticate,
  snackbarAction,
} from "../Authentication/AuthActions";
export const GET_PAYROLL_DATA = "[get payroll data] get payroll data";
export const GET_PAYROLL_STAFF_DATA =
  "[get payroll staff data] get payroll staff data";
export const HANDLE_CLIENT_ERROR = "[handle client error] handle client error";

export function getPayrollList(businessId, payrollData, navigate) {
  return (dispatch) => {
    getPayroll(businessId, payrollData)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getPayrollListData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetclient", error);
      });
  };
}

export function getPayrollStaffData(staffId, payrollData, navigate) {
  return (dispatch) => {
    getPayrollStaffContent(staffId, payrollData)
      .then((response) => {
        const { data, status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getPrStaffData(data));
        }
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetclient", error);
      });
  };
}

export function getPayrollListData(data) {
  return {
    type: GET_PAYROLL_DATA,
    payload: data,
  };
}

export function getPrStaffData(data) {
  return {
    type: GET_PAYROLL_STAFF_DATA,
    payload: data,
  };
}

export function handleClientError(data) {
  return {
    type: HANDLE_CLIENT_ERROR,
    payload: data,
  };
}
