import { lazy } from "react";
import Loadable from "../components/Loadable";
import BusinessSetupLayout from "../layout/BusinessSetupLayout";
import AuthGuard from "../services/AuthGuard";
import { CountryProvider } from "../pages/settings/businessSetup/BusinessDetails/CountryCOntext";

// Settings Route
const BusinessDetails = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/BusinessDetails/BusinessDetails")
  )
);

const LocationDetails = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/LocationDetails/LocationDetails")
  )
);
const PhoneNumberDetail = Loadable(
  lazy(() =>
    import(
      "../pages/settings/businessSetup/PhoneNumberDetail/PhoneNumberDetail"
    )
  )
);
const BrandAndLogo = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/BrandAndLogo/BrandAndLogo")
  )
);

const BusinessPayments = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/Payments/BusinessPayments")
  )
);
const ServiceDeduction = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/ServiceDeduction/ServiceDeduction")
  )
);
const AdvancedSettings = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/AdvancedSettings/AdvancedSettings")
  )
);
// ==============================|| MAIN ROUTING ||============================== //

const BusinessSetupRoutes = {
  path: "settings/business-setup/",
  element: (
    <AuthGuard auth={true}>
      <BusinessSetupLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "business-details",
      element: (
        <CountryProvider>
          <BusinessDetails />
        </CountryProvider>
      ),
    },
    {
      path: "locations",
      element: (
        <CountryProvider>
          <LocationDetails />
        </CountryProvider>
      ),
    },
    {
      path: "phone-numbers",
      element: <PhoneNumberDetail />,
    },
    {
      path: "logo-branding",
      element: <BrandAndLogo />,
    },
    {
      path: "payments",
      element: <BusinessPayments />,
    },
    {
      path: "service-deduction",
      element: <ServiceDeduction />,
    },

    {
      path: "advanced",
      element: <AdvancedSettings />,
    },
  ],
};

export default BusinessSetupRoutes;
