import { authApi } from "./AxiosInstance";

export function createBusiness(userData) {
  return authApi.post("/api/create_business", userData);
}

export function updateBusiness(userData) {
  return authApi.post("/api/edit_business", userData);
}

export function editBusinessSetting(settingData) {
  return authApi.post("/api/edit_business_setting", settingData);
}

export function getBusiness(id) {
  return authApi.get(`/api/get_business/:?id=${id}`);
}
export function paymentQrCode(businessId, data) {
  return authApi.post(`/api/upload_qr_code_image/${businessId}`, data);
}

export function deleteQrCode(data) {
  return authApi.post(`/api/remove_qr_code_image`, data);
}

export function uploadCoverAndLogoImage(content) {
  return authApi.post("/api/upload_cover_image", content);
}

export function removeCoverAndLogoImage(content) {
  return authApi.post("/api/remove_logo", content);
}

export function addBusinessLocation(locationData) {
  return authApi.post("/api/add_location", locationData);
}

export function updateBusinessLocation(locationData) {
  return authApi.post("/api/edit_location", locationData);
}

export function deleteBusinessLocation(locationData) {
  return authApi.post("/api/delete_location", locationData);
}

export function addServiceDeduction(serviceDeductionData) {
  return authApi.post("/api/add_service_deduction", serviceDeductionData);
}

export function getServiceDeductionData(businessId) {
  return authApi.get(`/api/get_service_deduction/${businessId}`);
}
