import {
  checkInCheckOut,
  getAllCheckoutList,
  getAllCheckInActiveStaff,
} from "../../../services/StaffCheckIn";
import moment from "moment";
import {
  handlingFailedToAuthenticate,
  loaderConfirm,
  snackbarAction,
} from "../Authentication/AuthActions";
export const GET_ALL_CHECKOUT_LIST =
  "[get all checkout list] get all checkout list";
export const GET_ALL_CHECKIN_ACTIVE_STAFF_LIST =
  "[get all checkout active staff list] get all checkout active staff list";
export const CHECK_IN_STATE = "[check in state] check in state";

export function getAllCheckInStaffList(checkoutData, navigate) {
  return (dispatch) => {
    // dispatch(loaderConfirm("getAllCheckInStaffList"));

    getAllCheckoutList(checkoutData)
      .then((response) => {
        const { status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCheckoutList(response?.data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetAllcheckout", error);
      });
  };
}

export function getAllCheckInStaffActiveUserList(checkoutData, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("getAllcheckinActiveStaff"));

    getAllCheckInActiveStaff(checkoutData)
      .then((response) => {
        const { status, code } = response?.data;
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          dispatch(getCheckInActiveStaff(response?.data));
        }
        dispatch(loaderConfirm(""));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("errorgetAllcheckout", error);
      });
  };
}

export function CheckInCheckOutStaff(checkInCheckOutData, navigate) {
  return (dispatch) => {
    checkInCheckOut(checkInCheckOutData)
      .then((response) => {
        const { data, message, status, code } = response?.data;

        let sendMessage = {
          code: 200,
          message: message,
        };
        if (status === "error") {
          handlingFailedToAuthenticate(code, navigate);
        } else {
          const checkData = {
            businessId: data?.businessId,
            // date: new Date(),
            date : moment().format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            )        
        
        
          };
          dispatch(getAllCheckInStaffList(checkData, navigate));
          dispatch(checkInState(false));
        }
        dispatch(snackbarAction(true, sendMessage));
      })
      .catch((error) => {
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        console.log("erroradd-staff", error);
      });
  };
}

export function getCheckoutList(data) {
  return {
    type: GET_ALL_CHECKOUT_LIST,
    payload: data,
  };
}

export function getCheckInActiveStaff(data) {
  return {
    type: GET_ALL_CHECKIN_ACTIVE_STAFF_LIST,
    payload: data,
  };
}

export function checkInState(data) {
  return {
    type: CHECK_IN_STATE,
    payload: data,
  };
}
