import { LOGOUT_ACTION } from "../actions/Authentication/AuthActions";
import {
  GET_NEW_BUSINESS,
  GET_LOCATION_STATUS,
  GET_MODAL_STATUS,
  GET_VIRTUAL_STEPS,
  RELOAD_GET_BUSINESS_API,
  GET_SERVICE_DEDUCTION_DATA,
} from "../actions/Business-setup/BusinessSetupActions";

const initialState = {
  getBusiness: [],
  locationStatus: "",
  locationModalStatus: false,
  getVirtalLocationStep: "",
  reloadBusinessApi: false,
  servDeduction: {},
};
export function BusinessSetupReducers(state = initialState, action) {
  if (action.type === GET_NEW_BUSINESS) {
    return {
      ...state,
      getBusiness: action.payload,
    };
  }
  if (action.type === GET_LOCATION_STATUS) {
    return {
      ...state,
      locationStatus: action.payload,
    };
  }
  if (action.type === RELOAD_GET_BUSINESS_API) {
    return {
      ...state,
      reloadBusinessApi: action.payload,
    };
  }
  if (action.type === GET_MODAL_STATUS) {
    return {
      ...state,
      locationModalStatus: action.payload,
    };
  }
  if (action.type === GET_VIRTUAL_STEPS) {
    return {
      ...state,
      getVirtalLocationStep: action.payload,
    };
  }
  if (action.type === GET_SERVICE_DEDUCTION_DATA) {
    return {
      ...state,
      servDeduction: action.payload,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return initialState;
  }
  return state;
}
