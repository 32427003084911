import { getUserProfile, login, signUp,checkoutTrigger, Forget, resetPassword } from "../../../services/AuthService";

export const SNACKBAR_ACTION = "[snackbar action] snackbar user";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING = "[loading action] loading";
export const GET_USER_PROFILE = "[get user profile] get user profile";
export const LOGOUT_ACTION = "[Logout action] logout action ";
export const LOGIN_USER_ACTION = "[login action] login user";

export function handlingFailedToAuthenticate(error, navigate) {
  if (error === 505) {
    console.log("errrsadsadsad", error);
    navigate("/auth/login");
    localStorage.clear();
  }
}
export function loginAction(userCredentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("login"));
    login(userCredentials)
      .then((response) => {
        console.log(response, "response login");
        if (response?.data?.status === "error") {
          dispatch(loaderConfirm(""));
        } else {
          dispatch(loginConfirmedAction(response?.data));
          localStorage.setItem(
            "access_token",
            JSON.stringify(response?.data?.data?.[0]?.access_token)
          );
          localStorage.setItem(
            "userId",
            JSON.stringify(response?.data?.data?.[0]?._id)
          );
          localStorage.setItem(
            "businessId",
            JSON.stringify(response?.data?.data?.[0]?.businessId)
          );
          dispatch(loaderConfirm(""));

          navigate("/appointments");
        }
        let message = {
          code: response?.data?.code,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(loaderConfirm(""));

        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        // handleErrors(dispatch, error, navigate);
      });
  };
}

export function signupAction(userCredentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("register"));
    signUp(userCredentials)
      .then((response) => {
        if (response?.data?.status === "error") {
          let message = {
            code: response?.data?.code,
            message: response?.data?.message,
          };
          dispatch(snackbarAction(true, message));
          navigate("/auth/register");
          dispatch(loaderConfirm(""));
        } else {
          let message = {
            code: response?.data?.code,
            message: response?.data?.message,
          };
          dispatch(snackbarAction(true, message));

          // Store form data in localStorage
          // localStorage.setItem("signupForm", JSON.stringify(userCredentials));
          navigate("/auth/login");
        }
        let message = {
          code: response?.data?.code,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(loaderConfirm(""));
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
      });
  };
}

export function saveUserDetails(id, navigate) {
  return (dispatch) => {
    getUserProfile(id)
      .then((response) => {
        if (response?.data?.status === "error") {
          handlingFailedToAuthenticate(response?.data?.code, navigate);

          let message = {
            code: response?.data?.code,
            message: response?.data?.message,
          };
          dispatch(snackbarAction(true, message));
          // dispatch(Logout(navigate));
        } else {
          dispatch(loginSaveAction(response?.data));
        }
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        console.error(error);
      });
  };
}

export function Logout(navigate) {
  const token = JSON.parse(localStorage.getItem("access_token"));
  if (token) {
    localStorage.clear();
  }

  navigate("/auth/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function CheckOutAction(data, navigate) {
  return (dispatch) => {
    checkoutTrigger(data)
      .then((response) => {
        console.log("After Checkout Trigger",response);
      })
      .catch((error) => {
       console.log("Error catch in checkout",error);
       
      });
  };
}


export function snackbarAction(value, message) {
  return {
    type: SNACKBAR_ACTION,
    payload: message,
    open: value,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function loaderConfirm(data) {
  return {
    type: LOADING,
    payload: data,
  };
}
export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}
export function loginSaveAction(data) {
  return {
    type: LOGIN_USER_ACTION,
    payload: data,
  };
}


export function forgetActon(userCredentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("Forget"));
    Forget(userCredentials)
      .then((response) => {
        if (response?.data?.status === "error") {
          dispatch(loaderConfirm(""));
        } else {
          dispatch(loginConfirmedAction(response?.data));
          // localStorage.setItem(
          //   "access_token",
          //   JSON.stringify(response?.data?.data?.[0]?.access_token)
          // );
          // localStorage.setItem(
          //   "userId",
          //   JSON.stringify(response?.data?.data?.[0]?._id)
          // );
          // localStorage.setItem(
          //   "businessId",
          //   JSON.stringify(response?.data?.data?.[0]?.businessId)
          // );
          dispatch(loaderConfirm(""));

          // navigate("/auth/login");
        }
        let message = {
          code: response?.data?.code,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(loaderConfirm(""));

        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        // handleErrors(dispatch, error, navigate);
      });
  };
}



export function resetAction(userCredentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm("resetPassword"));
    resetPassword(userCredentials)
      .then((response) => {
        if (response?.data?.status === "error") {
          dispatch(loaderConfirm(""));
        } else {
          dispatch(loginConfirmedAction(response?.data));
          // localStorage.setItem(
          //   "access_token",
          //   JSON.stringify(response?.data?.data?.[0]?.access_token)
          // );
          // localStorage.setItem(
          //   "userId",
          //   JSON.stringify(response?.data?.data?.[0]?._id)
          // );
          // localStorage.setItem(
          //   "businessId",
          //   JSON.stringify(response?.data?.data?.[0]?.businessId)
          // );
          dispatch(loaderConfirm(""));

          navigate("/auth/login");
        }
        let message = {
          code: response?.data?.code,
          message: response?.data?.message,
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(loaderConfirm(""));

        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message,
        };
        dispatch(snackbarAction(true, message));
        // handleErrors(dispatch, error, navigate);
      });
  };
}