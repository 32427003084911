import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../MainLayout/Header/Header";

// ==============================|| MAIN LAYOUT ||============================== //

const SettingsLayout = () => {
  return (
    <Box>
      <Header />
      <Box component="main" sx={{ paddingTop: "56px" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SettingsLayout;
