import axios from "axios";
export const apiUrl = process.env.REACT_APP_API_URL;

export const authApi = axios.create({
  baseURL: apiUrl,
});

authApi.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("access_token"));

    if (token) {
      // config.headers["access_token"] = token;
      config.headers["Authorization"] = `Bearer ${token}`;
      // config.headers.access_token = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
