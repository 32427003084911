import { lazy } from "react";
import Loadable from "../components/Loadable";
import AuthGuard from "../services/AuthGuard";
import PaymentAndCheckoutLayout from "../layout/PaymentAndCheckoutLayout";

// Settings Route

const BusinessPayments = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/Payments/BusinessPayments")
  )
);

const ServiceDeduction = Loadable(
  lazy(() =>
    import("../pages/settings/businessSetup/ServiceDeduction/ServiceDeduction")
  )
);

// ==============================|| MAIN ROUTING ||============================== //

const PaymentsAndCheckout = {
  path: "settings/payment-checkout/",
  element: (
    <AuthGuard auth={true}>
      <PaymentAndCheckoutLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "payments",
      element: <BusinessPayments />,
    },
    {
      path: "service-deduction",
      element: <ServiceDeduction />,
    },
  ],
};

export default PaymentsAndCheckout;
